import React from "react"
import styles from "../css/titlev2.module.css"
const Title = ({ title }) => {
  return (
    <section className={styles.effectTabHeader}>
      <div align="center">
        <h1 className={styles.title}>{title}</h1>
      </div>
    </section>
  )
}
export default Title
