import React from "react"
import ProductList from "./product-list"

const Gallery = ({ productosHombre, productosMujer }) => {
  return (
    <div>
      <ProductList
        productosHombre={productosHombre}
        productosMujer={productosMujer}
      />
    </div>
  )
}

export default Gallery
