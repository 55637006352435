import React from "react"
import Layout from "../components/layout-component.js"
import Gallery from "../components/Gallery/gallery-component"
import { graphql } from "gatsby"
import StyledHero from "../components/StyledHero"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
const Galeria = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({ id: "titleGaleria" })}`}
        description={`${intl.formatMessage({ id: "descGaleria" })}`}
        keywords={[
          `${intl.formatMessage({ id: "GaleriaKeyword1" })}`,
          `${intl.formatMessage({ id: "GaleriaKeyword2" })}`,
          `${intl.formatMessage({ id: "GaleriaKeyword3" })}`,
          `${intl.formatMessage({ id: "GaleriaKeyword4" })}`,
          `${intl.formatMessage({ id: "GaleriaKeyword5" })}`,
          `${intl.formatMessage({ id: "GaleriaKeyword6" })}`,
        ]}
        lang={intl.locale}
      />
      <StyledHero img={data.galleryHero.childImageSharp.fluid}></StyledHero>
      <Gallery
        productosHombre={data.productos_hombre}
        productosMujer={data.productos_mujer}
      />
    </Layout>
  )
}

export const query = graphql`
  query($locale: String) {
    galleryHero: file(relativePath: { eq: "fondoSombrero1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    productos_hombre: allContentfulBravohatsData(
      filter: { node_locale: { eq: $locale }, hombre: { eq: true } }
    ) {
      edges {
        node {
          nombre
          slug
          hombre
          mujer
          contentful_id
          descripcion {
            descripcion
          }
          etiquetas {
            etiqueta
          }
          precios {
            calidad
            precio
          }
          imagenes {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    productos_mujer: allContentfulBravohatsData(
      filter: { node_locale: { eq: $locale }, hombre: { eq: false } }
    ) {
      edges {
        node {
          nombre
          slug
          hombre
          mujer
          contentful_id
          descripcion {
            descripcion
          }
          etiquetas {
            etiqueta
          }
          precios {
            calidad
            precio
          }
          imagenes {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default Galeria
